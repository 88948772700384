import "./css/MoviePage.css";
import banner from "../../images/movie-banner.jpeg";

import { useEffect, useState } from "react";
import MovieModel from "../../models/MovieModel";
import MovieEpisodeModel from "../../models/MovieEpisodeModel";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import { VideoPlayer } from "./components/VideoPlayer";
import { MovieCardProfile } from "./components/MovieCardProfile";

interface VideoDimensions {
  height: number;
  width: number;
}

export const MoviePage = () => {
  const moviePoster = window.location.pathname.split("/")[2];
  const [movie, setMovie] = useState<MovieModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  const [bannerHeight, setBannerHeight] = useState<number>(300);
  const [videoId, setVideoId] = useState<string>(`${moviePoster}`);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [desktopClassName, setDesktopClassName] = useState<string>("");
  const [desktopDimensions, setDesktopDimensions] = useState<VideoDimensions>({
    height: 0,
    width: 0,
  });

  const [tabletDimensions, setTabletDimensions] = useState<VideoDimensions>({
    height: 0,
    width: 0,
  });

  const [mobileClassName, setMobileClassName] = useState<string>("");
  const [mobileDimensions, setMobileDimensions] = useState<VideoDimensions>({
    height: 0,
    width: 0,
  });

  const videoIdKey = `movie_${moviePoster}_videoId`;
  const activeIndexKey = `movie_${moviePoster}_activeIndex`;

  useEffect(() => {
    const storedVideoId = localStorage.getItem(videoIdKey);
    const storedActiveIndex = localStorage.getItem(activeIndexKey);

    if (storedVideoId) {
      setVideoId(storedVideoId);
    }

    if (storedActiveIndex) {
      setActiveIndex(parseInt(storedActiveIndex, 10));
    }
  }, [videoIdKey, activeIndexKey]);

  useEffect(() => {
    const fetchMovie = async () => {
      const url: string = `${process.env.REACT_APP_API_URI}/api/movies/search/movieposter?value=${moviePoster}`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }

        const responseJson = await response.json();

        const loadedEpisodes: MovieEpisodeModel[] = responseJson.episodes.map(
          (episode: any) =>
            new MovieEpisodeModel(
              episode.episode_id,
              episode.episodeNumber,
              episode.title.toUpperCase(),
              episode.videoId,
              episode.description
            )
        );

        loadedEpisodes.sort((a, b) => a.episode_number - b.episode_number);

        const loadedMovie: MovieModel = new MovieModel(
          responseJson.movieId,
          responseJson.title.toUpperCase(),
          responseJson.uploadDate,
          responseJson.releaseYear,
          responseJson.genre,
          responseJson.moviePoster,
          loadedEpisodes,
          responseJson.uploadChannel,
          responseJson.dateModified
        );

        setMovie(loadedMovie);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setHttpError(error.message);
      }
      window.scroll(0, 0);
    };

    fetchMovie();
  }, [moviePoster]);

  useEffect(() => {
    localStorage.setItem(videoIdKey, videoId);
    localStorage.setItem(activeIndexKey, activeIndex.toString());
  }, [activeIndex, videoId, videoIdKey, activeIndexKey]);

const checkDesktopSize = () => {
  const width = window.innerWidth;

  if (width >= 1200) {
    setDesktopClassName("desktop-mp-vp-d-xl-block");
    setDesktopDimensions({ height: 600, width: 920 });
    setBannerHeight(400);
  } else if (width >= 992 && width < 1200) {
    setDesktopClassName("desktop-mp-vp-d-lg-block");
    setDesktopDimensions({ height: 600, width: 780 });
    setBannerHeight(300);
  } else if (width >= 768 && width < 992) {
    setDesktopClassName("desktop-mp-vp-d-md-block");
    setDesktopDimensions({ height: 300, width: 580 });
    setBannerHeight(250);
  } else if (width >= 576 && width < 768) {
    setDesktopClassName("desktop-mp-vp-d-sm-block");
    setDesktopDimensions({ height: 300, width: 410 });
    setBannerHeight(225);
  }
  else {
    setDesktopClassName("desktop-mp-vp-d-sm-none");
    setDesktopDimensions({ height: 300, width: 375 });
    setBannerHeight(200);
  }
};

  const checkTabletSize = () => {
    const width = window.innerWidth;

    if (width >= 992 && width < 1200) {
      setTabletDimensions({ height: 600, width: 750 });
    } else if (width >= 768 && width < 992) {
      setTabletDimensions({ height: 300, width: 550 });
    } else {
      setTabletDimensions({ height: 300, width: 320 });
    }
  };

  const checkMobileSize = () => {
    const width = window.innerWidth;

    if (width >= 768 && width < 992) {
      setMobileClassName("mobile-mp-vp-d-md-block");
      setMobileDimensions({ height: 300, width: 550 });
    } else if (width >= 576 && width < 768) {
      setMobileClassName("mobile-mp-vp-d-sm-block");
      setMobileDimensions({ height: 300, width: 500 });
    } else {
      setMobileClassName("mobile-mp-vp");
      setMobileDimensions({ height: 300, width: 320 });
    }
  };

  useEffect(() => {
    checkDesktopSize();
    checkTabletSize();
    checkMobileSize();
    window.addEventListener("resize", checkDesktopSize);
    window.addEventListener("resize", checkTabletSize);
    window.addEventListener("resize", checkMobileSize);
    

    return () => {
      window.removeEventListener("resize", checkDesktopSize);
      window.removeEventListener("resize", checkTabletSize);
      window.removeEventListener("resize", checkMobileSize);
    };
  }, []);

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <SpinnerLoading />
      </div>
    );
  }

  if (httpError) {
    return (
      <div className='container m-5'>
        <p>{httpError}</p>
      </div>
    );
  }

  const handleEpisodeButtonClick = (index: number, videoId: string) => {
    setActiveIndex(index);
    setVideoId(videoId);
  };

  const generateEpisodeGroups = (
    episodesGroupLength: number
  ): React.ReactNode[] => {
    return (movie?.episodes || []).reduce<React.ReactNode[]>(
      (subGroup, episode, index) => {
        if (index % episodesGroupLength === 0) {
          subGroup.push(
            <ul key={index} className='list-group list-group-horizontal gap-3'>
              {[
                ...Array(
                  Math.min(
                    episodesGroupLength,
                    (movie?.episodes?.length ?? 0) - index
                  )
                ),
              ].map((_, i) => {
                const episodeIndex = index + i;
                return (
                  <li key={i}>
                    <button
                      className={`mt-2 list-group-item list-group-item-action rounded${
                        episodeIndex === activeIndex ? " active" : ""
                      }`}
                      type='button'
                      onClick={() =>
                        handleEpisodeButtonClick(
                          episodeIndex,
                          movie?.episodes[episodeIndex].video_Id ?? ""
                        )
                      }>
                      Episode {movie?.episodes[episodeIndex].episode_number}
                    </button>
                  </li>
                );
              })}
            </ul>
          );
        }
        return subGroup;
      },
      []
    );
  };

  return (
      <main className='container p-5 mt-5'>
        {/* ----------------------------------------- Desktop -----------------------------------------------*/}
        {isDesktop && (
          <>
            <div className='d-none desktop-mp d-xl-block'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='d-none desktop-mp d-lg-block d-xl-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='d-none desktop-mp d-md-block d-lg-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='d-none desktop-mp d-sm-block d-md-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='desktop-mp d-sm-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className={desktopClassName}>
              <VideoPlayer videoId={videoId} height={`${desktopDimensions.height}`} width={`${desktopDimensions.width}`} />
            </div>

            <div className='d-none desktop-mp d-xl-block'>
              <div className='d-flex flex-column mt-5 mx-auto'>
                {generateEpisodeGroups(8)}
              </div>
            </div>

            <div className='d-none desktop-mp d-lg-block d-xl-none'>
              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(6)}
              </div>
            </div>

            <div className='d-none desktop-mp d-md-block d-lg-none'>
              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(4)}
              </div>
            </div>

            <div className='d-none desktop-mp d-sm-block d-md-none'>
              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(3)}
              </div>
            </div>

            <div className='desktop-mp d-sm-none'>
              <div className='d-flex flex-column mt-5 mx-2'>
                {generateEpisodeGroups(2)}
              </div>
            </div>

            <section
              id='banner-desktop'
              style={{
                position: "relative",
                backgroundImage: `url(${banner})`,
                height: `${bannerHeight}px`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "100%",
                backgroundColor: "black",
              }}
            />
          </>
        )}

        {/* ----------------------------------------- Tablet -----------------------------------------------*/}
        {isTablet && (
          <>
            <div className='d-none tablet-mp d-lg-block d-xl-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='d-none tablet-mp d-md-block d-lg-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='tablet-mp-vp'>
              <VideoPlayer
                videoId={videoId}
                height={`${tabletDimensions.height}`}
                width={`${tabletDimensions.width}`}
              />
            </div>

            <div className='d-none tablet-mp d-lg-block d-xl-none'>
              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(6)}

                <section
                  className='mt-2'
                  style={{
                    width: "1000px",
                    height: "700px",
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "150%",
                    backgroundRepeat: "no-repeat",
                    marginLeft: "-120px",
                  }}
                />
              </div>
            </div>

            <div className='d-none tablet-mp d-md-block d-lg-none'>
              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(4)}

                <section
                  className='mt-2'
                  style={{
                    width: "800px",
                    height: "600px",
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "150%",
                    backgroundRepeat: "no-repeat",
                    marginLeft: '-100px'
                  }}
                />
              </div>
            </div>
          </>
        )}

        {/* ----------------------------------------- Mobile -----------------------------------------------*/}
        {isMobileOnly && (
          <>
            <div className='d-none mobile-mp d-md-block d-lg-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='d-none mobile-mp d-sm-block d-md-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className='mobile-mp d-sm-none'>
              {movie && <MovieCardProfile movie={movie} />}
            </div>

            <div className={mobileClassName}>
              <VideoPlayer
                videoId={videoId}
                height={`${mobileDimensions.height}`}
                width={`${mobileDimensions.width}`}
              />
            </div>

            <div className='d-none mobile-mp d-md-block d-lg-none'>
              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(4)}

                <section
                  className='mt-2'
                  style={{
                    width: "700px",
                    height: "600px",
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "150%",
                    backgroundRepeat: "no-repeat",
                    marginLeft: '-80px'
                  }}
                />
              </div>
            </div>

            <div className='d-none mobile-mp d-sm-block d-md-none'>
              <div className='d-flex flex-column mt-3'>
                {generateEpisodeGroups(3)}

                <section
                  className='section-d-sm-block mt-2'
                  style={{
                    width: "700px",
                    height: "600px",
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "150%",
                    backgroundRepeat: "no-repeat",
                    marginLeft: '-50px'
                  }}
                />
              </div>
            </div>

            <div className='mobile-mp d-sm-none'>
              <div className='d-flex flex-column mt-3 mx-3'>
                {generateEpisodeGroups(2)}

                <section
                  className='section-d-sm-none mt-3'
                  style={{
                    width: "500px",
                    height: "350px",
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "150%",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </div>
            </div>
          </>
        )}
      </main>
  );
};
